import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a Textarea when a user can add multiple lines of text.`}</p>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <p>{`The minimum and maximum number of rows can be set to display a recommended
length for a response. Once the max-height of the Textarea is reached, a scroll
bar appears to indicate the submitted input outreaches the available space.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<Box mb={5} clone>
  <Textarea
    id="two-lines"
    label="Write us a story"
    rows={2}
    message="A Textarea with a max-height of 2 rows."
  />
  <Textarea
    id="three-lines"
    label="Write us a story"
    rows={3}
    message="A Textarea with a max-height of 3 rows."
  />
</Box>
`}</code></pre>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "labels",
      "style": {
        "position": "relative"
      }
    }}>{`Labels`}</h3>
    <p>{`Every Textarea should have a label. A field without a label is ambiguous and not
accessible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<Textarea
  id="labels"
  label="Textarea"
  placeholder="Every Textarea should have a label"
  type="text"
/>
`}</code></pre>
    <h3 {...{
      "id": "placeholders",
      "style": {
        "position": "relative"
      }
    }}>{`Placeholders`}</h3>
    <p>{`Placeholder text is displayed inside the field but is not always necessary. They
should be written as examples helping users to understand the required input
instead of instructions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<Box mb={5} clone>
  <Textarea
    id="placeholder-one"
    label="Write us a story"
    placeholder="e.g. A cinderalla story"
    type="text"
  />
  <Textarea
    id="placeholder-two"
    label="The lyrics of your favourite song"
    type="text"
  />
</Box>
`}</code></pre>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`If Textarea requires less text, consider using a
`}<a parentName="li" {...{
          "href": "/components/textfield/code"
        }}>{`TextField`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      